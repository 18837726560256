// @file Global alert dialog store
import { defineStore } from 'pinia'
import { ref } from 'vue'

interface AlertDialogPayload {
  iconSrc?: string | null
  iconAlt?: string | null
  title?: string
  body?: string
  closeButtonText?: string
  xShadow?: boolean
  shouldFadeIn?: boolean
  afterCloseActions?: Array<() => void>
}

const defaultAlertDialogData: AlertDialogPayload = {
  iconSrc: null,
  iconAlt: null,
  title: '',
  body: '',
  closeButtonText: '',
  xShadow: false,
  shouldFadeIn: false,
  afterCloseActions: [],
}

export const useGlobalAlertDialogStore = defineStore('globalAlertDialog', () => {
  const isOpen = ref<boolean>(false)
  const alertDialogData = ref<AlertDialogPayload>(defaultAlertDialogData)

  const openAlertDialog = (payload: AlertDialogPayload): void => {
    isOpen.value = true
    alertDialogData.value = Object.assign({}, alertDialogData.value, payload)
  }

  const resetAlertDialog = (): void => {
    isOpen.value = false
    alertDialogData.value = Object.assign({}, alertDialogData.value, defaultAlertDialogData)
  }

  const executeCloseActions = (actions?: Array<() => void>): void => {
    if (actions === undefined) return

    actions.forEach((action): void => {
      if (typeof action === 'function') {
        action()
      }
    })
  }

  const closeAlertDialog = (): void => {
    executeCloseActions(alertDialogData.value?.afterCloseActions)
    resetAlertDialog()
  }

  return {
    // State
    isOpen,
    alertDialogData,

    // Actions
    openAlertDialog,
    closeAlertDialog,
  }
})
